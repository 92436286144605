<template>
  <div class="home">
    <div class="head-img">
      <img src="../assets/qyjj-top.png" alt="">
    </div>
    <div class="w1200 attract-intor-box">
      <div class="right">
        <div class="title-box">公司简介</div>
        <div class="intro-box">
          <div class="text-box">
            <p>
              甘肃黄河三峡旅游投资开发有限公司于2015年10月底正式挂牌运营。注册资金4.08亿元，为县属国有企业，主要
              负责永靖县范围内所有旅游资源的投资、招商、规划、开发、整合、建设、经营、管理和包装上市；旅游船舶运营
              及出租车运营。公司承担着项目建设、经营管理、旅游商品开发、配套设施建设、投融资五大职能。公司现有职工
              86人（包括公职人员4人）。其中总经理1人；副总经理2名；综合办公室2人；财务2人；出租车2人；保洁1人；
              太极岛景区37人（其中：售票员6人、观光车司机10人、码头派船1人、保洁12、保安2人、值夜班1人、门岗1
              人、厨师1人）；博览馆8人（其中：讲解员4人、保洁2人、值班1人、职工1人）；夜游码头4人（其中：安全员2
              人；驾驶员2人）；黄河三峡旅行社8人，滑翔营地8人（其中：职工3人、售票员1人、保安2人、保洁2人）。恐
              龙博物馆12人（其中：讲解员4人、水暖工1人、电工1人、保安2人、保洁4人）公司下设永靖县西部水乡旅游有
              限责任公司、永靖县黄河三峡旅游汽车出租有限公司、甘肃黄河三峡旅行社有限公司、甘肃黄河三峡实业有限公司
              和刘家峡国际滑翔营地。
            </p>
          </div>
          <div class="img-box">
            <img src="../assets/gsjj01.png" alt="">
          </div>
        </div>
        <div class="intro-box" style="align-items: flex-end;">
          <div class="text-box">
            <h3>企业重点产业发展现状</h3>
            <p>
              永靖县西部水乡旅游有限责任公司主要负责对黄河三峡景区内旅游船舶实行统一管理，规范化经营，现公司委托经
              营管理旅游船舶143艘（其中大坝100艘，太极岛43艘），公司自有4艘，所有船舶统一排班、统一售票，有序经
              营。永靖县黄河三峡旅游汽车出租有限公司有出租汽车50辆，出租车以对外招租的方式在本县运营，所有出租车司机
              经过严格的面试和培训，整体服务水平和职业素质较高，目前运气情况良好。
              甘肃黄河三峡旅行社有限公司运营以来秉持“游客至上、安全第一、诚信经营”的经营理念，先后开辟出国内旅游
              线路47条，并与国内多家旅行社建立了良好的合作关系。能满足不同游客的需求，旅行社还承揽甘肃省内地接线
              路、黄河三峡大景区的游客接待和乘船业务以及省内周边景点的环线游览。
              刘家峡国际滑翔营地于去年9月成功举办全国定点滑翔伞比赛，并于11月正式投入运营，现有优秀的滑翔伞教练8
              名，能为广大游客体验飞翔的乐趣，目前经营状况良好，二期建设项目已陆续投入，部分已完工并投入使用。
            </p>
          </div>
          <div class="img-box">
            <img src="../assets/gsjj02.png" alt="">
          </div>
        </div>
        <div class="intro-box" style="align-items: flex-end;">
          <div class="img-box">
            <img src="../assets/gsjj03.png" alt="">
          </div>
          <div class="text-box">
            <h3>企业经营管理情况</h3>
            <p>
              (一)是对炳灵湖景区旅游船舶进行统一管理，加强对旅游船员定期培训学习和安全教育，提升广大船员的服务水平
              和安全意识。配合海事、公安、交警、运管等部门查处违规违章船舶，杜绝超员超载现象，打击黑车、黑导，整治
              景区旅游秩序，促进景区旅游环境有序、有规，健康稳步发展。(二)是在太极湖景区黄河绿道投放电动观光车29
              辆，单人、2人、3人、4人自行车290辆，丰富当地居民及游客的休闲健身和体验项目，同时极大的促进了景区服
              务环境协调性发展。(三)是在太极湖投放3艘夜游仿古画舫，配合太极湖夜游亮化工程优美的夜景灯光效果，让游
              客欣赏我县美丽夜景，提升我县城市品位。四是于2018年11月全面运营刘家峡国际滑翔营地。营地于2018年6月
              动工建设，9月完工投入使用，并成功举办了2018年全国滑翔伞定点联赛（永靖站），现有优秀的滑翔伞教练8
              名，能为广大游客提供飞行体验的乐趣，极大的填补了我县冬季旅游的空白，目前经营状况良好，营地依托营地优
              势，辐射和带动周边农户农家乐和民宿发展，促使周边农户增收，稳定脱贫。
            </p>
          </div>
        </div>
        <div class="intro-box">
          <div class="text-box" style="width: 100%;">
            <h3>企业资产情况</h3>
            <p>
              现有国有资产账面价值68744.56万元，其中：固定资产21602.67万元，包括房屋建筑面积50471.48平方米，账面价值20733.56万元，通用设备账面价值698.46万元（车辆账面价值139.02万元，机械设备488.82万元），电子设备70.62万元，办公家具账面价值170.65万元。无形资产19734.74万元，其中土地使用权（使用面积149505.60平方米，土地使用证正在办理中）账面价值19734.74万元。
            </p>
          </div>
        </div>
        <div class="intro-box">
          <div class="text-box">
            <h3>企业项目发展情况</h3>
            <p>
              永靖沿黄太极岛段湿地修复与综合治理项目，主题工程已经完工，该项目对太极岛景区进行了全面升级改造，改善
              太极岛景区旅游环境，提升服务档次。同时改善了县城黄河滨水湿地生态系统，树立了永靖新形象，加快了发展改
              革的步伐，进一步推动了全县旅游产业的发展。永靖县太极大桥工程项目，该项目进一步完善了县城路网系统，健
              全了县城道路交通体系，缓解了黄河两岸交通拥挤的状况。永靖县滨河南路景观工程，该项目对县城区黄河南岸水
              土流失问题得到了有效治理，并且进一步完善了城市功能，提升了城市品位，打造了美丽永靖。太极湖夜游亮化项
              目，该项目对县城黄河两岸进行了亮化，提升了我县城市品位。刘家峡国际滑翔伞营地项目，该项目丰富了我县的
              旅游项目，对永靖旅游起到了很好的宣传作用。
            </p>
          </div>
          <div class="img-box">
            <img src="../assets/gsjj04.png" alt="">
          </div>
        </div>
        <div class="intro-box">
          <div class="img-box">
            <img src="../assets/gsjj05.png" alt="">
          </div>
          <div class="text-box">
            <h3>企业未来发展计划</h3>
            <p>
              着眼于建设科学规范、创新务实、朝气活力的现代化国有企业，全面加强公司队伍建设，为公司发展各项业务提供
              坚强的组织保障。在公司建立党支部，以及工会、妇联组织，开展经常性的党建工作和群团活动，充分发挥好党组
              织的战斗堡垒和党员的先锋模范作用，增强企业的凝聚力和战斗力。注重学习，积极创造各种机会，让公司员工多
              学习先进的管理理念和业务知识。坚持因岗用人、人尽其用，坚决克服人浮于事，公司养懒人。严把公司人员进
              口，除保安、保洁人员外，公司招人必须具备本科以上学历，建设一支精干、高效，能干成事的企业管理队伍。注
              重加强导游队伍建设，严把质量，重点培养，鼓励讲解员报考导游证。逐步提高员工工资待遇，落实社会保障制
              度，优化工作环境，实现待遇留人，发展留人。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyIntro",

}
</script>

<style scoped lang="scss">
  .head-img{
    width: 100%;
    height: 316px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .attract-intor-box{
    margin-top: 20px;
    display: flex;
    .right{
      flex: 1;
      margin-bottom: 36px;
      .title-box{
        box-sizing: border-box;
        width: 131px;
        height: 43px;
        line-height: 43px;
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        padding-left: 20px;
        text-align: left;
        background-image: url(../assets/attract-title-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 55px;
      }
      .intro-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 65px;
        .text-box{
          width: 720px;
          h3{
            font-size: 18px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            text-align: left;
            margin-bottom: 5px;
          }
          p{
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 1.9;
            text-align: left;
          }
        }
        .img-box{
          width: 430px;
          height: 252px;
        }
      }
    }
  }

  
  @media screen and (max-width: 780px) {
    .home{
      .head-img{
        height: 7.8rem;
      }
      .attract-intor-box{
        box-sizing: border-box;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        .right{
          flex: 1;
          margin-bottom: 36px;
          .title-box{
            box-sizing: border-box;
            width: 6.5rem;
            height: 2.1rem;
            line-height: 2.1rem;
            font-size: 0.9rem;
            padding-left: 1rem;
            margin-bottom: 1rem;
          }
          .intro-box{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            .text-box{
              width: 100%;
              h3{
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
              }
              p{
                font-size: 0.7rem;
                line-height: 1.9;
              }
            }
            .img-box{
              width: 100%;
              height: auto;
              margin: 0.5rem 0;
              img{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>